body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiDrawer-paper {
  width: 425px !important;
}

.MuiDrawer-paperAnchorLeft {
  width: 355px !important;
}

.MuiListItem-root {
  display: block !important;
}

.MuiBadge-anchorOriginTopRightRectangle {
  top: -5px !important;
  right: -5px !important;
}

@media (max-width: 767px) {
  .MuiDrawer-paper {
    width: 100% !important;
  }
  .MuiDrawer-paperAnchorLeft {
    width: 100% !important;
  }
  .MuiListItem-root {
    display: flex !important;
  }
  .MuiBadge-anchorOriginTopRightRectangle {
    top: -1px !important;
  }
}
iframe{
  z-index: -20 !important;
}
